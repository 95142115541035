body {
  background-color: #2a2a2a;
  font-family: Open Sans;
}

.dx-widget {
  font-family: Open Sans;
}

.dx-theme-generic-typography {
  font-weight: 400;
  font-size: 15px;
  font-family: Open Sans;
  line-height: 1.35715;
}

p {
  margin-top: 1em;
  margin-bottom: 1em;
}

.card-page {
  margin-bottom: 100px;
}

.copyright {
  margin-top: 50px;
  background: #010101;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.copyright>p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.copyright-info>p>a {
  color: #ba8238;
  text-decoration: none;
  font-size: 14px;
}

.copyright-info>p>span {
  color: #b3b3b3;
  font-size: 14px;
}

.footer-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.logo {
  height: 50em;
  display: block;
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
}

.tile-image {
  width: 100%;
  background-position: center;
  background-size: cover;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 800px) {

  /* styles for devices with a maximum screen width of 800px */
  .logo {
    height: 20em;
  }

  .copyright {
    padding: 0;
  }
}